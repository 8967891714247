.sheet {
  z-index: 10000;
  position: fixed;
  height: calc(100vh + 100px);
  width: 100%;
  border-radius: 12px 12px 0px;
  background: #fff;
  touch-action: none;
}

.sheetMenuAcertia {
  z-index: 10000;
  position: fixed;
  height: calc(100vh + 100px);
  width: 100%;
  border-radius: 12px 12px 0px;
}


.overlay {
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}


.signer {
    background:#f7f7f7;
    padding:26px;
    cursor:pointer;
    width: 100vw;
}

.header {
    color: #5585ed;
    font-weight: bold;
    font-size: 14pt;
    margin: 10px 30px;
}

.handler {
    /* background: #5585ed; */
    height: 5px;
    margin: auto;
    border-radius: 8px;
    width: 150px;
    margin-top: 10px;
}

.handlerContainer {
    height:20px;
}