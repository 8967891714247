


html,body {
  height: 100%;
  margin: 0;  
  color: #484848;
}


#root, .App {
  height:100%
}

.noOverflow {
  overflow: hidden;
}


.form-group {
  text-align:left
}

label {
  font-size: 12px;
  font-weight: bold;
}

#page-container { /* PDF container */
  margin:0; 
  padding:0px;
  border:0; /* required for lazy page loading in pdf2htmlEX.js (page visibility test) */
  transform-origin:0 0;
  transform: scale(1);
}
@media screen {
  /* for sidebar */
  #sidebar.opened + #page-container { left:250px; }
  #page-container {
    /* `bottom' and `right' are required for lazy page loading in pdf2htmlEX.js (page visibility test)
     * alternatively you may set width and height
     */
    bottom:0;
    right:0;
  }
  .loading-indicator {
    display:none;
  }
  .loading-indicator.active {
    display:block;
    position:absolute;
    width:64px;
    height:64px;
    top:50%;
    left:50%;
    margin-top:-32px;
    margin-left:-32px;
  }
  .loading-indicator img {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
  }
}
@media print { 
  @page { margin:0; }
  html { margin:0; }
  body { 
    margin:0; 
    -webkit-print-color-adjust:exact; /* enable printing background images for WebKit */
  }
  #sidebar { display:none; }
  #page-container {
    width:auto;
    height:auto;
    overflow:visible;
    background-color:transparent;
  }
  .d { display:none; }
}
/* Part 2: Page Elements: Modify with caution
 * The followings are base classes, some of which are meant to be override by PDF specific classes
 * So do not increase the specificity (e.g. ".classname" -> "#page-container .classname")
 */
.pf { /* page */
  position:relative;
  background-color:white;
  overflow: hidden;
  margin:0; 
  border:0; /* required by pdf2htmlEX.js for page visibility test */
}
.pc { /* content of a page */
  position:absolute;
  border:0;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow:hidden;
  display:block;
  /* set transform-origin for scaling */
  transform-origin:0% 0%;
  -ms-transform-origin:0% 0%;
  -webkit-transform-origin:0% 0%;
}
.pc.opened { /* used by pdf2htmlEX.js, to show/hide pages */
  display:block;
}
.bf { /* images that occupies the whole page */
  position:absolute;
  border:0;
  margin:0;
  top:0;
  bottom:0;
  width:100%;
  height:100%;
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  user-select:none;
}
.bi { /* images that cover only a part of the page */
  position:absolute;
  border:0;
  margin:0;
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  user-select:none;
}
@media print {
  .pf {
    margin:0;
    box-shadow:none;
    page-break-after:always;
    page-break-inside:avoid;
  }
  @-moz-document url-prefix() {
    /* fix page truncation for FireFox */
    .pf {
      overflow:visible;
      border:1px solid #FFFFFF;
    }
    .pc {overflow:visible;}
  }
}
.c { /* clip box */
  position:absolute;
  border:0;
  padding:0;
  margin:0;
  overflow:hidden;
  display:block;
}
.t { /* text line */
  position:absolute;
  white-space:pre;
  font-size:1px;
  transform-origin:0% 100%;
  -ms-transform-origin:0% 100%;
  -webkit-transform-origin:0% 100%;
  unicode-bidi:bidi-override;/* For rtl languages, e.g. Hebrew, we don't want the default Unicode behaviour */
  -moz-font-feature-settings:"liga" 0;/* We don't want Firefox to recognize ligatures */
}
.t:after { /* webkit #35443 */
  content: '';
}
.t:before { /* Workaround Blink(up to 41)/Webkit bug of word-spacing with leading spaces (chromium #404444 and pdf2htmlEX #412) */
  content: '';
  display: inline-block;
}
.t span { /* text blocks within a line */
  /* Blink(up to 41)/Webkit have bug with negative word-spacing and inline-block (pdf2htmlEX #416), so keep normal span inline. */
  position:relative;
  unicode-bidi:bidi-override; /* For rtl languages, e.g. Hebrew, we don't want the default Unicode behaviour */
}
._ { /* text shift */
  /* Blink(up to 41)/Webkit have bug with inline element, continuous spaces and word-spacing. Workaround by inline-block. */
  display: inline-block;
  color: transparent;
  z-index: -1;
}
/* selection background should not be opaque, for fallback mode */
::selection{
  background: rgba(127,255,255,0.4);
}
::-moz-selection{
  background: rgba(127,255,255,0.4);
}
.pi { /* info for Javascript */
  display:none;
}
.l { /* annotation links */
}
/* transparent color - WebKit */
.d { /* css drawing */
  position:absolute;
  transform-origin:0% 100%;
  -ms-transform-origin:0% 100%;
  -webkit-transform-origin:0% 100%;
}
/* for the forms */
.it {
  border: none;
  background-color: rgba(255, 255, 255, 0.0);
}

.ir:hover {
  cursor: pointer;
}

/* Base CSS END */
.Page {  
  margin-top:10px;
  margin-bottom: 10px;
}

.Page > .pf {
  border: 2px solid #e0e8f1 !important;
  margin: auto !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom:0 !important;
}

.MainContainer {
  width: 100%;
  /* touch-action:none; */
}

.LoadingTasks .modal-content {
  background: transparent;
  border:0;
}

.LoadingTasks .Task {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  outline: 0;
  padding: 20px;
  margin:10px;
}

.imageDialog {
	text-align: center;
  }

.imageDialog img {
	padding: 20px;
	width: 300px;
    margin: auto;
    display: block;
  }

  .imageDialog p {
	margin: 20px;
  }

  .imageDialogTitle {
	text-transform: uppercase;
	font-weight: bold;
	color:#0b66c9;
  }

  .imageDialogSeparator {
	width: 30px;
    border: 1px solid #00c8dc;
    display: block;
    margin: 20px auto;
  }

::-webkit-scrollbar-x {
  width: 0px;
  background: transparent;
}

@keyframes dualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.move-enter {
  opacity: 0.01;
  transform: translate(-40px, 0)
}

.move-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 500ms ease-in;
}

.move-exit {
  opacity: 1;
  transform: translate(0, 0)
}

.move-exit-active {
  opacity: 0.01;
  transform: translate(40px, 0);
  transition: all 500ms ease-in;
}

.btn {
  padding-right: 20px;
  padding-left:20px;
  border-radius: 40px;
}

.btn-blue {
  background: #0966c9;
  color:white;
  font-weight: bold;
  margin:20px;
  padding:6px 40px;
}

.slick-list {
  padding:0 20% 0 20% !important;
}

.blue-text {
  color: #0966c9;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
}

.blue {
  color: #0966c9;
}

.feedbackIcon {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.feedbackIcon:hover {
  width: 60px;
  height: 60px;
}

.feedbackIcon.selected {
  width: 60px;
  height: 60px;
}

.pinNumber {
  margin: 1px;
  height: 80px;
  font-size: 50px;
  text-align: center;
  padding: 0;
}

.pinNumber::-webkit-outer-spin-button,
.pinNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pinNumber[type=number] {
  -moz-appearance: textfield;
}

.GeoLocateOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	background-color: black;
	background: rgba(0,0,0,0.85);
  z-index: 10000;
}

.GeoLocateOverlayMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	background-color: black;
	background: rgba(0,0,0,0.85);
  z-index: 10000;
}

.TextOverlayMobile, .TextOverlayMobile p {
  color: white;
  text-align: center;
  font-weight: bold;
  display: block;
  font-size: 20px;
  margin:20px;
}

.GeoLocateOverlay span {
  color: white;
  text-align: center;
  font-weight: bold;
  display: block;
  font-size: 24px;
}

.loading-task-error {
  color: red;
  font-weight: bold;
}


@keyframes pulse {
  0% {
    @include transform(scale(.9));
  }
  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
    100% {
    @include transform(scale(.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}