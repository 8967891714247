.container {
  padding: 1px;
  border-style: solid;
  border-width: 4px;
  border-image-source: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 80 80%22%3E%3Crect x=%224%22 y=%224%22 width=%2272%22 height=%2272%22 fill=%22transparent%22 stroke=%22%230966C9%22 stroke-width=%228%22 %2F%3E%3C%2Fsvg%3E");
  border-image-slice: 50%;
  border-image-width: 10px;
  cursor:pointer;
}

.containerDisabled {
  padding: 1px;
  border-style: solid;
  border-width: 4px;
  border-image-source: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 80 80%22%3E%3Crect x=%224%22 y=%224%22 width=%2272%22 height=%2272%22 fill=%22transparent%22 stroke=%22%230966C9%22 stroke-width=%228%22 %2F%3E%3C%2Fsvg%3E");
  border-image-slice: 50%;
  border-image-width: 5px;
  cursor:auto;
}

.containerNoBorder {
  padding: 1px;
  border-style: solid;
  border-color: transparent;
  border-width: 4px;
}

.background {
  background-color:#D7E8FA ;  
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background.disabled {
  background-color: #d6d6d6;
}

.tooltip {
  background: #0966c9;
  width: 200px;
  height: 60px;
  position: relative;
  top: -120px;
  color: white;
  padding: 10px;
  font-size: 13px;
  left: -30px;
  pointer-events: none;
}


.tooltip:before, .tooltip:after {
  top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tooltip:after {
	border-top-color: #0966c9;
	border-width: 15px;
	margin-left: -15px;
}

.reactstrapTooltip {
  background: #0966c9;
  border-color: #0966c9;
  z-index: 1;
}

.reactstrapTooltipArrow {
  border-color: #0966c9;
}

.reactstrapTooltipArrow::before {
  border-top-color: #0966c9 !important;
}