.modal {
    width: 100%;
    height: 100%;
    margin: 0;
}

.modalHeader {
    background: #0966c9;
    border-radius: 0;
    color:#fff;
    font-weight: bold;    
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.backIcon {
    position: absolute;
    left:20px;
}

.modalContent {
    min-height: 100vh;
    border-radius: 0;
}

.controlButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -90px !important;
    width: 180px;
}