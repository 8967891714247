.container {
    width: 54px;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;    
    padding-bottom: 20px;
}

.container img {
    cursor: pointer;
}