
.button {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #5A5A5A;
    cursor: pointer;
}

.disabled {
    cursor: 'auto';
    color: 'gray'
}