
.header {
    color:#5282F0;
    font-weight: bold;
    padding:20px 30px;
}

.header button {
    color:#5282F0;
    opacity: 1;
}

.header h5 {
    font-size: 1.5rem;
}

.styleTitle {
    color: #464646;
    font-size: 25px;
}

.styleDescription {
    color: #6D7278;
    font-size: 14px;
}

.styleContainer {
    margin: 17px 32px;
    cursor: pointer;
}

.title {
    color:#5282F0;
    font-size: 25px;
}

.styleArrow {
    color:#5282F0;
}