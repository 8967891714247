.container, .containerMobile {
    position: fixed;
    left: 80px;
    top: 230px;
    font-size: 20px;
    color: white;
    background: #7f7f7f;
    padding: 10px 20px;
    border-radius: 20px;
    opacity: 0.8;
}

.containerMobile {
    top:130px;
    left: 20px;
}

.control {
    cursor: pointer;
}