.container {
    width: 900px;
    height: auto;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin-bottom: 40px;
}

.centerMobile {
    display: flex;
    justify-content: center;
}

.containerMobile {
    width: 286px;
}

.containerDocMobileLeft {
    width: 140px;
    margin-left: 138px;
}

.containerDocMobileRight {
    width: 140px;
    margin-right: 140px;
}

.containerDocLeft {
    width: 50%;
    margin-left: 49.7%;
}

.containerDocRight {
    width: 50%;
}

.titleDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.title {
    margin: 20px 0 0 0;
    font-weight: 600;
    font-size: 20px;
}

.titlePDF {
    max-width: 200px;
    overflow: auto;
    height: auto;
}

.subTitleDiv {
    width: 80%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DCD9D2;
    padding-bottom: 8px;
    font-size: 14px;
}

.expediente {
    margin: 25px;
    font-weight: 600;
    justify-content: center;
    display: flex;
}

.expedientesDiv {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.expedientesDivMobile {
    width: auto;
}

.infoDiv {
    width: 230px;
}

.infoDivMobile {
    width: 195px;
    margin-top: 3px;
}

.imgDivLeft {
    margin: 0 8px 0 15px;
}

.imgDivRight {
    margin: 0 8px 0 0;
}

.documentDiv {
    display: flex;
    font-size: 10px;
}

.documentDivMobile {
    flex-direction: column;
}

.documentDiv p {
    margin: 1px;
}

.documentCase {
    display: flex;
    padding-right: 45px;
    padding-top: 15px;
    border-top: 1px solid #DCD9D2;
    margin-bottom: 7px;
    min-height: 70px;
}

.documentCaseMobile {
    padding-top: 1px;
    padding-right: 15px;
}

.topBorder {
    width: 100%;
    height: 9px;
}

.circleRight {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: relative;
    top: 14px;
    right: -310px;
}

.circleRightMobile {
    right: -135px;
}

.circleLeft {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: relative;
    top: 13px;
    left: -3px;
}

.clickable {
    cursor: pointer;
}

.fechaTextMobile {
    width: 100px;
}

.fechaText {
    width: auto;
}

