.container {
    
}

.canvasWrapper {
    background-color:white; 
    margin:auto; 
    border: 1px solid #cfcfd0;
    border-radius: 5px;
    max-height: 300px;
}

.lineSeparator {
    bottom: 80px;
    position: relative;
    pointer-events: none;
    border-bottom: 1px solid #b3b3b3;	
}

.options {
    /* position: absolute;
    right: 30px;
    top: 20px; */
    margin-right: 5px;
    margin-left: -5px;
    font-size: 20pt;
}

.option {
    cursor: pointer;
    margin:10px 0
}

.colorPicker {
    width:25px;
    height: 25px;
    border-radius: 25px;
    cursor:pointer;
    /* background:#1569c6; */
}