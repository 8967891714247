
  
  .pulseButton {
    position: relative;
    display: block;
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
    animation: pulseAnimation 1.5s infinite;
  }
  
  .pulseButton:hover {
    animation: none;
  }
  
  @keyframes pulseAnimation {
    0% {
    }
    70% {
      box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
  }
  