.container {
    position: absolute;
    top: 111px;
    right: 100px;
}

.actionsDiv {
    width: 220px;
    /* height: 140px; */
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #B9B7B1;
}

.doctoName {
    margin-bottom: -15px;
    height: 30px;
    width: 220px;
    color: #8D8B87;
    font-family: Roboto;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: -1px;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid #B9B7B1;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.docInfoContainer {
    margin: 20px 0 0 0;
    font-size: 12px;
    color: #8D8B87;
    border-bottom: 1px solid #B9B7B1;
    padding-bottom: 20px;
}

.divDocumentInfo {
    display: flex;
}

.divDocumentInfo p {
    margin: 0 0 5px 0;
    font-size: 10px;
}

.infoTitle {
    margin-bottom: 10px;
}

.buttonDiv {
    width: 100%;
    display: flex;
    margin-top: -5px;
    justify-content: center;
}

.button {
    background-color: #B70577;
    color: #FFF;
    border-radius: 25px;
    height: 30px;
    max-width: 140px;
    width: 140px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 10px;
    margin: 20px 0 0 0;
    cursor: pointer;
    border: none;
    -webkit-user-select: none;
}

.space {
    margin-left: 2px !important;
}

.soporteContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.soporteEmail {
    color: #B70577;
    font-size: 12px;
    font-weight: 600;
}

.soporteCenter {
    text-align: center;
    margin-top: 30px;
}