
.header {
    color:#2b98f0;
    font-weight: bold;
    padding:20px 30px;
}

.header h5 {
    font-size: 1.5rem;
}


.body {
    background:#f7f7f7;
    overflow: hidden;
    padding:0
}

.signer {
    background:#f7f7f7;
    padding:26px;
    cursor:pointer;
}

.signerName {
    font-weight: bold;
}

.modalContent {
    overflow: hidden;
}

.valid {
    margin-right: 20px;
    font-size: 20px;
    color:#2b98f0;
}

.detailContent {
    padding:20px 30px;    
}

.validationBarText {
    margin-left: 20px;
}

.validationBarIcon {
    font-size: 25px;
    padding:10px
}

.detailSection {
    margin-top:10px;
}

.detailTitle {
    font-weight: bold;
}

.serial {
    word-break: break-all;
    cursor: pointer;
    color:#0966c9;
}