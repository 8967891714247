.container {
    /* padding: 1em; */
    /* border-style: solid;
    border-width: 0.25em;
    border-image-source: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 80 80%22%3E%3Crect x=%224%22 y=%224%22 width=%2272%22 height=%2272%22 fill=%22transparent%22 stroke=%22%230966C9%22 stroke-width=%228%22 %2F%3E%3C%2Fsvg%3E");
    border-image-slice: 50%;
    border-image-width: 10px;
    cursor:grab; */
    position: absolute;
}

.background {
    background-color:#D7E8FA ;  
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    width:95%;
    height: 95%;
    top:2.5%;
    left: 2.5%;
    pointer-events: none;
 }

 .handler {
    width:20px;
    height:20px;   
    position: absolute;
 }

 .bottomRight {
    cursor: se-resize;
     bottom:-10px;
     right:-10px;
 }

 .bottomLeft {
    cursor: sw-resize;
    bottom:-10px;
    left:-10px;
}

.topLeft {
    cursor: nw-resize;
    top:-10px;
    left:-10px;
}

.topRight {
    cursor: ne-resize;
    top:-10px;
    right:-10px;
}

.tooltip {
    background: #0966c9;
    width: 200px;
    height: 60px;
    position: relative;
    top: -100px;
    color: white;
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    pointer-events: none;
    text-align: center;
}

.arrowDown {
    width: 0; 
    height: 0; 
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid #0966c9;
    margin: auto;
    margin-top: 8px;
  }