.container {
  height: 90px;  
  padding-left: 20px;
  user-select: none;
  border-bottom: 1px solid transparent;
}


.container img {
  margin: 5px;
}

.verticalSeparator {
    height: 55px;
    background: #9f9f9f;
    width: 1px;
}

.userData {
    cursor: pointer;
}

.userName {
    font-weight: bold;
}

.iconDropdown {
    padding: 10px;
    margin-right:40px;
}

.dropdownMenu{
    border: none;
    box-shadow: 5px 5px 30px -9px rgba(143,143,143,1);
    border-radius: 10px;
    left:-5px !important;
}

.dropdownItem {
    color:#484848;
}

.dropdownItem:hover{
    color: #0966c9;    
}

.dropdownToggle button, .dropdownToggle button:hover, .dropdownToggle button:focus {
    background: transparent !important;
    border: none;
    box-shadow: none !important;
    outline: none !important;
}