
.button, .button:hover, .secondary {
  color:white;
  font-weight: bold;
  margin:5px !important;
  padding:6px 40px;
}

.button2, .button2:hover {
  color: #1D1C21;
  font-weight: bold;
  margin:5px !important;
  padding:6px 40px;
}

.margin, .margin:hover {
  margin: 20px !important;
}

.noMargin {
  margin: 5px !important
}


@media screen and (max-width: 350px) {
  .button2, .button2:hover {
    padding: 6px 27px;
  }
  .button, .button:hover {
    padding: 6px 27px;
  }
}