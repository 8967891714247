.modalTitle h5 {
    margin-left: 157px;
    color: #B70677;
}
.modalTitle {
    background: #E9E5DE;
}   
.guiaItemContainer {
    display: flex;
    margin: 10px 30px 20px 10px;
}
.guiaItemContainerRes {
    display:flex;
    margin: 10px 5px 20px 0px;
}
.itemName, .itemDescription {
    font-size: 12px;
}

.itemNameRes, .itemDescriptionRes {
    font-size: 10px;
}

.iconStyle {
    margin-right: 20px;
}

.iconStyleRes {
    margin-right: 10px;
}

.modalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
}

.modalCenterClose {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 31px;
}

.modalMobileCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
}