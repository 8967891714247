
.container {
    position: fixed;
    z-index: 10;
    bottom: 40px;
    right: 40px;
}

.containerMobile {
    flex-flow:nowrap;
    width: 100%;
    padding: 5px;
    position: fixed;
    z-index: 10;
    bottom: 5px;
    justify-content: center !important;
}

.containerMobile button {
    flex-grow: 1;
    margin:5px;
}